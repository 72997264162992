<template>
    <div class="TestResult">
        <img
            :src="
                pass
                    ? require('@/assets/correct.png')
                    : require('@/assets/err.png')
            "
            class="icon"
            alt=""
        />
        <div class="text">
            {{ pass ? "恭喜你，" : "很遗憾，" }}本次测试成绩为<span
                class="rate"
                >{{ score }}</span
            >
            分
        </div>
        <div class="tip" v-if="!pass">没有通过本次测试</div>
        <div
            class="err mt-27 click"
            v-if="paperScore != score && ifanalysis == 1"
            @click="errAnalysis"
        >
            错题解析
        </div>
        <div class="flex-jsc mt-19">
            <div class="err back click" @click="back">返回测评中心</div>
            <div
                class="err back ml-51 click"
                style="
                    background: linear-gradient(180deg, #ff3764, #ff2604);
                    color: #fff;
                "
                v-if="!pass"
                @click="again"
            >
                重新测试
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TestResult",
        data() {
            return {
                pass: true, //是否通过
                score: 0, //成绩
                qualified: 0, //及格分
                id: 0, //测评id
                paper: 0, //试卷id
                paperScore: 0, //总分
            };
        },

        mounted() {
            this.score = Number(this.$route.query.score);
            this.qualified = Number(this.$route.query.qualified);
            this.id = this.$route.query.test;
            this.paper = this.$route.query.paper;
            this.paperScore = this.$route.query.paperScore;
            this.pass = this.score >= this.qualified;
            this.ifanalysis = this.$route.query.ifanalysis;
        },

        methods: {
            //返回测评中心
            back() {
                this.$router.push({
                    path: "/Evaluation",
                });
            },
            //重新测试
            again() {
                this.$router.push({
                    path: "/InTest",
                    query: {
                        test: this.id,
                        ifretest: 1,
                        ifanalysis: this.ifanalysis,
                    },
                });
            },
            //错题解析
            errAnalysis() {
                this.$router.push({
                    path: "/errAnalysis",
                    query: { paper: this.paper },
                });
            },
        },
    };
</script>

<style lang="less" scoped>
.TestResult {
    width: 1200px;
    margin: 37px auto 157px auto;
    background: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .icon {
        width: 98px;
        height: 98px;
        margin-top: 109px;
        margin-bottom: 50px;
    }
    .text {
        font-size: 18px;
        font-weight: 500;
        color: #2d2d2d;
        display: flex;
        align-items: flex-end;
        .rate {
            color: #a0cc61;
            margin: 0 8px;
            font-size: 30px;
            line-height: 32px;
        }
    }
    .err {
        width: 120px;
        height: 33px;
        background: linear-gradient(180deg, #ff3764, #ff2604);
        border-radius: 17px;
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .back {
        background: #949494;
        margin-bottom: 110px;
    }
    .tip {
        font-size: 12px;
        font-weight: 400;
        color: #e04e4e;
        margin-top: 5px;
    }
}
</style>